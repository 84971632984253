export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/logo-xsmart.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'home',
        className: 'header3-item',
        children: {
          href: '/',
          children: [{ children: '首页', name: 'home' }],
        },
      },
      {
        name: 'productCenter',
        className: 'header3-item',
        children: {
          children: [{ children: '产品中心', name: 'text' }],
        },
        subItem: [
          {
            name: 'characterRecognition',
            className: 'item-sub',
            children: {
              href: '#characterRecognition',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '文字识别',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
          {
            name: 'targetDetection',
            className: 'item-sub',
            children: {
              href: '#targetDetection',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '目标检测',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
          {
            name: 'sizeMeasurement',
            className: 'item-sub',
            children: {
              href: '#sizeMeasurement',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '尺寸测量',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'coreTechnology',
        className: 'header3-item',
        children: {
          href: '#coreTechnology',
          children: [{ children: '核心技术', name: 'text' }],
        },
      },
      {
        name: 'customerCase',
        className: 'header3-item',
        children: {
          href: '#customerCase',
          children: [{ children: '客户案例', name: 'text' }],
        },
      },
      {
        name: 'aboutUs',
        className: 'header3-item',
        children: {
          href: '#aboutUs',
          children: [{ children: '关于我们', name: 'text' }],
        },
      },
      {
        name: 'contactUs',
        className: 'header3-item',
        children: {
          href: '#contactUs',
          children: [{ children: '联系我们', name: 'text' }],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>3C行业视觉检测</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>触控板尺寸测量</p>
          </span>
        ),
      },
    ],
  },
  img: {
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/chicunceliang.png',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: { children: '', className: 'content6-icon' },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <p>产品尺寸：苹果触控板尺寸</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: { className: 'content6-icon', children: '' },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>检测类型：触控板的宽高长度精确测量</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                <br />
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block2',
        img: { className: 'content6-icon', children: '' },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <p>误差范围：0.01mm以内</p>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block~khzkvtseakc',
        img: { className: 'content6-icon', children: '' },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>产品匀速运动，检测正面</p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block~khzkvx1rtma',
        img: { className: 'content6-icon', children: '' },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <p>多种产品规格</p>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block~khzkvya9kou',
        img: { className: 'content6-icon', children: '' },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>激光3D相机，激光三角测量</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block~khzkw0iemwk',
        img: { className: 'content6-icon', children: '' },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <p>丰富的检测算法，高精度，抗干扰</p>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
      },
    ],
  },
};
export const Feature41DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>制造行业视觉检测-轴承</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>轴 承</p>
          </span>
        ),
      },
    ],
  },
  img: {
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/bearing2.png',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: { children: '', className: 'content6-icon' },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <p>精确计算工件形心，精准找到形心位置</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: { className: 'content6-icon', children: '' },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>不同产品规格尺寸测量，并进行质量评估</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                <br />
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block2',
        img: { className: 'content6-icon', children: '' },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <p>配置提醒等级，异常报警</p>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
      },
    ],
  },
};
export const Feature42DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>塑料加工行业视觉检测-PVC管</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>P V C</p>
          </span>
        ),
      },
    ],
  },
  img: {
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/pvc.png',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: { children: '', className: 'content6-icon' },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <p>不同产线多角度采集</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: { className: 'content6-icon', children: '' },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>针对不同产品规格进行检测，及时进行质量评估</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                <br />
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block2',
        img: { className: 'content6-icon', children: '' },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <p>实时定位缺陷位置，并能获取缺陷尺寸大小</p>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block~khzkvtseakc',
        img: { className: 'content6-icon', children: '' },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>定位产品编码，检测时段，人员，快速追踪</p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block~khzkvx1rtma',
        img: { className: 'content6-icon', children: '' },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <p>多种产品规格</p>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
      },

    ],
  },
};
export const Feature43DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>X 射线行业视觉检测</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>x射线成像图片智能检测</p>
          </span>
        ),
      },
    ],
  },
  img: {
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/x-ray1.png',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: { children: '', className: 'content6-icon' },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <p>辅助安检机解决安检的智能检测功能</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: { className: 'content6-icon', children: '' },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>解决生产企业的缺陷内部检测问题，可适用行业：半导体、轮胎、锂电池、金属加工、化工、食品、医药</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                <br />
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block2',
        img: { className: 'content6-icon', children: '' },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <p>根据场景 <span style={{color:'red'}}>综合正确率>=90%-95%</span></p>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/logo-smart-white.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [{ name: 'content0', children: 'Xsmart TECH CO.,LTD' }],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '产品中心' },
        childWrapper: {
          children: [
            { name: 'link1', href: '#characterRecognition', children: '文字识别' },
            { name: 'link2', href: '#targetDetection', children: '目标检测' },
            { name: 'link3', href: '#sizeMeasurement', children: '尺寸测量' },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于' },
        childWrapper: {
          children: [
            { href: '#coreTechnology', name: 'link0', children: '核心技术' },
            { href: '#customerCase', name: 'link1', children: '客户案例' },
            { href: '#aboutUs', name: 'link1', children: '关于我们' },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '联系我们' },
        childWrapper: { children: [
            { href: '#contactUs', name: 'link1', children: 'AFX' },
          ] },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2020 by <a href="http://xmart.mygasmart.com">Xsmart</a> All Rights
        Reserved
      </span>
    ),
  },
};
