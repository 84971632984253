export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/logo-xsmart.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'home',
        className: 'header3-item',
        children: {
          href: '/',
          children: [{ children: '首页', name: 'home' }],
        },
      },
      {
        name: 'productCenter',
        className: 'header3-item',
        children: {
          children: [{ children: '产品中心', name: 'text' }],
        },
        subItem: [
          {
            name: 'characterRecognition',
            className: 'item-sub',
            children: {
              href: '#characterRecognition',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '文字识别',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
          {
            name: 'targetDetection',
            className: 'item-sub',
            children: {
              href: '#targetDetection',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '目标检测',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
          {
            name: 'sizeMeasurement',
            className: 'item-sub',
            children: {
              href: '#sizeMeasurement',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '尺寸测量',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'coreTechnology',
        className: 'header3-item',
        children: {
          href: '#coreTechnology',
          children: [{ children: '核心技术', name: 'text' }],
        },
      },
      {
        name: 'customerCase',
        className: 'header3-item',
        children: {
          href: '#customerCase',
          children: [{ children: '客户案例', name: 'text' }],
        },
      },
      {
        name: 'aboutUs',
        className: 'header3-item',
        children: {
          href: '#aboutUs',
          children: [{ children: '关于我们', name: 'text' }],
        },
      },
      {
        name: 'contactUs',
        className: 'header3-item',
        children: {
          href: '#contactUs',
          children: [{ children: '联系我们', name: 'text' }],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/mubiao1.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <p>印 刷 缺 陷 检 测</p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          在纸张、塑料等各种材质上印刷文字和图案，其印刷过程容易出现不同程度的细节缺陷。人工检验效率低、误差大。利用机器视觉检测技术，能精准、快速地检测出印刷品上的字符缺失、油墨深污、黑点、斑点、文字模糊起皱、套印不准、划伤等缺陷，节省人力成本。
        </p>
      </span>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/mubiao2.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <span>
          <p>钢 印 缺 陷 检 测</p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>
          排除背景和环境等干扰性因素问题，利用机器视觉检测技术，针对钢印字符缺失模糊、黑污斑点、上下偏移、错位等问题进行系统提醒以及报警处理。
        </p>
      </span>
    ),
  },
};
export const Feature11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/mubiao3.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <p>生 物 芯 片 成 像 缺 陷 检 测</p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <span>
            <p>
              利用生物芯片检测技术可帮助医生有效掌握癌症病患的病情发展，以及追踪患者对各种疗程的反应等。<span
              >
                尽管此技术可以实现信号的放大、增强和可视化，但是实际应用场景中很容易出现芯片成像质量整体不佳、各种异常问题。比如以下案例中对于蛋白质芯片的检测容易出现拖尾、明暗不均匀、异常亮点、点型不规整等问题。
              </span>
            </p>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature21DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/mubiao4.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>电 子 屏 幕 缺 陷 检 测</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>
          利用视觉检测技术对手机屏幕中出现的白点、白团、黑点、黑团、彩色团、亮点、条纹、划痕等缺陷检测。
        </p>
      </span>
    ),
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/logo-smart-white.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [{ name: 'content0', children: 'Xsmart TECH CO.,LTD' }],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '产品中心' },
        childWrapper: {
          children: [
            { name: 'link1', href: '#characterRecognition', children: '文字识别' },
            { name: 'link2', href: '#targetDetection', children: '目标检测' },
            { name: 'link3', href: '#sizeMeasurement', children: '尺寸测量' },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于' },
        childWrapper: {
          children: [
            { href: '#coreTechnology', name: 'link0', children: '核心技术' },
            { href: '#customerCase', name: 'link1', children: '客户案例' },
            { href: '#aboutUs', name: 'link1', children: '关于我们' },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '联系我们' },
        childWrapper: { children: [
            { href: '#contactUs', name: 'link1', children: 'AFX' },
          ] },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2020 by <a href="http://xmart.mygasmart.com">Xsmart</a> All Rights
        Reserved
      </span>
    ),
  },
};
