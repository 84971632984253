//导航栏
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/logo-xsmart.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'home',
        className: 'header3-item',
        children: {
          href: '/',
          children: [{ children: '首页', name: 'home' }],
        },
      },
      {
        name: 'productCenter',
        className: 'header3-item',
        children: {
          children: [{ children: '产品中心', name: 'text' }],
        },
        subItem: [
          {
            name: 'characterRecognition',
            className: 'item-sub',
            children: {
              href: '#characterRecognition',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '文字识别',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
          {
            name: 'targetDetection',
            className: 'item-sub',
            children: {
              href: '#targetDetection',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '目标检测',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
          {
            name: 'sizeMeasurement',
            className: 'item-sub',
            children: {
              href: '#sizeMeasurement',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '尺寸测量',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'coreTechnology',
        className: 'header3-item',
        children: {
          href: '#coreTechnology',
          children: [{ children: '核心技术', name: 'text' }],
        },
      },
      {
        name: 'customerCase',
        className: 'header3-item',
        children: {
          href: '#customerCase',
          children: [{ children: '客户案例', name: 'text' }],
        },
      },
      {
        name: 'aboutUs',
        className: 'header3-item',
        children: {
          href: '#aboutUs',
          children: [{ children: '关于我们', name: 'text' }],
        },
      },
      {
        name: 'contactUs',
        className: 'header3-item',
        children: {
          href: '#contactUs',
          children: [{ children: '联系我们', name: 'text' }],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
}
//内容部分1
export const Banner00DataSource = {
  wrapper: { className: 'banner0 khd7fbrkld7-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: 'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
  },
  content: {
    className: 'banner0-content',
    children: '一个高效的页面动画解决方案',
  },
  button: { className: 'banner0-button', children: 'Learn More' },
};
export const Banner01DataSource = {
  wrapper: { className: 'banner0 khd9axmtn1b-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: 'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
  },
  content: {
    className: 'banner0-content',
    children: '一个高效的页面动画解决方案',
  },
  button: { className: 'banner0-button', children: 'Learn More' },
};
//内容部分2
export const Banner30DataSource = {
  wrapper: { className: 'banner3 khd791eyzge-editor_css' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: '南京交道网络科技有限公司',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: '探索极致用户体验与最佳工程实践探索',
      },
      { name: 'button', className: 'banner3-button', children: '了解更多' },
      { name: 'time', className: 'banner3-time', children: '中国·南京' },
    ],
  },
};
export const Banner31DataSource = {
  wrapper: { className: 'banner3 khd8wb3mvc-editor_css' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: '南京交道网络科技有限公司',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: '探索极致用户体验与最佳工程实践探索',
      },
      { name: 'button', className: 'banner3-button', children: '了解更多' },
      { name: 'time', className: 'banner3-time', children: '中国·南京' },
    ],
  },
};
export const Banner32DataSource = {
  wrapper: { className: 'banner3 khd8yzqs1kc-editor_css' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: '南京交道网络科技有限公司',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: '探索极致用户体验与最佳工程实践探索',
      },
      { name: 'button', className: 'banner3-button', children: '了解更多' },
      { name: 'time', className: 'banner3-time', children: '中国·南京' },
    ],
  },
};
//页脚
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/logo-smart-white.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [{ name: 'content0', children: 'Xsmart TECH CO.,LTD' }],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '产品中心' },
        childWrapper: {
          children: [
            { name: 'link1', href: '#characterRecognition', children: '文字识别' },
            { name: 'link2', href: '#targetDetection', children: '目标检测' },
            { name: 'link3', href: '#sizeMeasurement', children: '尺寸测量' },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于' },
        childWrapper: {
          children: [
            { href: '#coreTechnology', name: 'link0', children: '核心技术' },
            { href: '#customerCase', name: 'link1', children: '客户案例' },
            { href: '#aboutUs', name: 'link1', children: '关于我们' },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '联系我们' },
        childWrapper: { children: [
            { href: '#contactUs', name: 'link1', children: 'AFX' },
          ] },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2020 by <a href="http://xmart.mygasmart.com">Xsmart</a> All Rights
        Reserved
      </span>
    ),
  },
};
