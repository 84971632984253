export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/logo-xsmart.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'home',
        className: 'header3-item',
        children: {
          href: '/',
          children: [{ children: '首页', name: 'home' }],
        },
      },
      {
        name: 'productCenter',
        className: 'header3-item',
        children: {
          children: [{ children: '产品中心', name: 'text' }],
        },
        subItem: [
          {
            name: 'characterRecognition',
            className: 'item-sub',
            children: {
              href: '#characterRecognition',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '文字识别',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
          {
            name: 'targetDetection',
            className: 'item-sub',
            children: {
              href: '#targetDetection',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '目标检测',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
          {
            name: 'sizeMeasurement',
            className: 'item-sub',
            children: {
              href: '#sizeMeasurement',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '尺寸测量',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'coreTechnology',
        className: 'header3-item',
        children: {
          href: '#coreTechnology',
          children: [{ children: '核心技术', name: 'text' }],
        },
      },
      {
        name: 'customerCase',
        className: 'header3-item',
        children: {
          href: '#customerCase',
          children: [{ children: '客户案例', name: 'text' }],
        },
      },
      {
        name: 'aboutUs',
        className: 'header3-item',
        children: {
          href: '#aboutUs',
          children: [{ children: '关于我们', name: 'text' }],
        },
      },
      {
        name: 'contactUs',
        className: 'header3-item',
        children: {
          href: '#contactUs',
          children: [{ children: '联系我们', name: 'text' }],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
    //   {
    //   name:'image',
    //   className:'title-image',
    //   children:
    //     'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/homePicture2.jpg'
    // },
      {
        name: 'title',
        children: (
          <span>
            <p>南京交道网络科技有限公司</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <p>
                  公司坐落于南京未来科技城，作为园区引进企业，我们致力于基于AI技术为企业提供算法开发、软件开发、整体AI应用解决方案，打造企业级AIOT平台。
                </p>
                <p>
                  作为科技研发型企业，目前有研发人员20余人，我们自主研发AI应用平台及算法，拥有软件著作权21件。我们期待用AI为每一个企业赋能！
                  <br />
                </p>
              </span>
            </span>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            <br/>
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>我 们 的 原 则</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>聚焦客户价值</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>所有的解决方案都紧密围绕客户价值，提高效率，降低成本！</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                   <span>
                     <p>坚持落地实行</p>
                   </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    概念无法解决问题，坚持在工业场景中的实践落地，用效果践行承诺！
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>用AI技术赋能</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>
                      基于AI的技术特点，降低AI使用门槛，为设备、产线、工厂全方位赋能！
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature01DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
            <p>我 们 的 坚 持</p>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/drawer1.jpg',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>拥 抱 变 化</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/drawer2.jpg',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                   <span>
                     <p>激 情 主 动</p>
                   </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/drawer3.jpg',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>坦 诚 简 单</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block3',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/drawer4.jpg',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>聚焦客户价值</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },

          ],
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/logo-smart-white.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [{ name: 'content0', children: 'Xsmart TECH CO.,LTD' }],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '产品中心' },
        childWrapper: {
          children: [
            { name: 'link1', href: '#characterRecognition', children: '文字识别' },
            { name: 'link2', href: '#targetDetection', children: '目标检测' },
            { name: 'link3', href: '#sizeMeasurement', children: '尺寸测量' },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于' },
        childWrapper: {
          children: [
            { href: '#coreTechnology', name: 'link0', children: '核心技术' },
            { href: '#customerCase', name: 'link1', children: '客户案例' },
            { href: '#aboutUs', name: 'link1', children: '关于我们' },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '联系我们' },
        childWrapper: { children: [
            { href: '#contactUs', name: 'link1', children: 'AFX' },
          ] },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2020 by <a href="http://xmart.mygasmart.com">Xsmart</a> All Rights
        Reserved
      </span>
    ),
  },
};
