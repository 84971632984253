import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Form, Input, InputNumber, Button } from 'antd';
import Content11 from "./Content11";
import {getChildrenToRender} from "../aboutUs/utils";

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

const onFinish = values => {
  console.log(values);
};

const validateMessages = {
  required: '${label}不能为空',
}

class leaveWords extends React.PureComponent {
  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const {
      wrapper,
      titleWrapper,
      page,
      OverPack: overPackData,
      childWrapper,
    } = dataSource;
    return (
      <div {...props} {...wrapper}>
        <div {...page}>
          <div {...titleWrapper}>
            {titleWrapper.children.map(getChildrenToRender)}
          </div>
          <Form {...formLayout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
            <Form.Item name={['leaveForm', 'name']} label="您的姓名" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name={['leaveForm', 'phone']} label="联系电话" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name={['leaveForm', 'title']} label="留言标题" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name={['leaveForm', 'type']} label="留言性质" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name={['leaveForm', 'detail']} label="留言内容" rules={[{ required: true }]}>
              <Input.TextArea rows={4}/>
            </Form.Item>
            <Form.Item wrapperCol={{ ...formLayout.wrapperCol, offset: 8 }}>
              <Button type="primary" htmlType="submit">
                发表
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    )
  }
}

export default leaveWords;
