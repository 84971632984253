export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/logo-xsmart.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'home',
        className: 'header3-item',
        children: {
          href: '/',
          children: [{ children: '首页', name: 'home' }],
        },
      },
      {
        name: 'productCenter',
        className: 'header3-item',
        children: {
          children: [{ children: '产品中心', name: 'text' }],
        },
        subItem: [
          {
            name: 'characterRecognition',
            className: 'item-sub',
            children: {
              href: '#characterRecognition',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '文字识别',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
          {
            name: 'targetDetection',
            className: 'item-sub',
            children: {
              href: '#targetDetection',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '目标检测',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
          {
            name: 'sizeMeasurement',
            className: 'item-sub',
            children: {
              href: '#sizeMeasurement',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '尺寸测量',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'coreTechnology',
        className: 'header3-item',
        children: {
          href: '#coreTechnology',
          children: [{ children: '核心技术', name: 'text' }],
        },
      },
      {
        name: 'customerCase',
        className: 'header3-item',
        children: {
          href: '#customerCase',
          children: [{ children: '客户案例', name: 'text' }],
        },
      },
      {
        name: 'aboutUs',
        className: 'header3-item',
        children: {
          href: '#aboutUs',
          children: [{ children: '关于我们', name: 'text' }],
        },
      },
      {
        name: 'contactUs',
        className: 'header3-item',
        children: {
          href: '#contactUs',
          children: [{ children: '联系我们', name: 'text' }],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};

export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/qrcode2.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>扫 码 产 品 追 溯</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          针对纸箱、纸杯、产品盖膜、产品卷料等包装物料上的条码进行识别，用来识别产品的详细信息(如制造厂商、商品名称、生产日期、制造地点等等)，方便查询产品的真伪。
        </p>
      </span>
    ),
  },
};
export const Feature11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/ocr2.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>钢 印 OCR</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          <span>
            除了物料本身的产品质量，包装容器的质量也尤为重要。由此利用机器视觉针对钢瓶钢印、纸盒等包装物料上的钢印进行识别，可获取产品的详细信息(如制造厂商、产品批号、生产日期、有效日期等等)，追溯批次，追溯单产品。
          </span>
        </p>
      </span>
    ),
  },
};
export const Feature12DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/ocr4.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>证 卡 票 券 OCR</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          针对证卡票券进行识别，用来识别票据和证件的详细信息，不用每次都手动输入数据对照。
        </p>
        <p>
          <br />
        </p>
        <p>
          当条码或二维码读取失败后，可通过票证识别系统，智能读取票证数据。<br />
        </p>
        <p>
          <br />
        </p>
        <p>
          码与文字常常一起运用以实现数据搜集流程牢靠性的最大化。<br />
        </p>
      </span>
    ),
  },
};

export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/ocr1.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>喷 码 OCR</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <span>
          <p>
            <span>
              由于纸盒、塑料薄膜、卷料、纸箱等包装喷码字符字体类型、大小、粗细都有所不同，人为判断容易造成识别信息不准确，识别速度慢，采用喷码OCR识别，正确读取产品包装信息，对产品质量严格把关。
            </span>
            <br />
          </p>
        </span>
      </span>
    ),
  },
};
export const Feature21DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/ocr3.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>标 签 OCR</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <span>
          <span>
            <span>
              <p>
                针对快递单等标签文字进行识别，用来识别产品的详细信息，分格、分行、分列智能读取标签上的各类重要信息，追寻产品的全部包装流程
              </p>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature22DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/ocr5.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>图 片 OCR</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>截图一键识别，快速识别出图片</p>
        <p>
          <br />
        </p>
        <p>支持单张图片上传和图片批量上传，支持各种格式图片识别出文字</p>
      </span>
    ),
  },
};

export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>条 码 / 二 维&nbsp; 码 识 别</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
      },
    ],
  },
  img: {
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/qrcode1.png',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          className: 'content6-icon',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <p>
                    针对气瓶进行生产管理，生产人员通过扫码枪扫描气瓶条码，可以进行生产过程的充装登记以及信息维护等操作。
                  </p>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>
                        <br />
                      </p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>针对气瓶进行资产管理，对气瓶追溯，记录收发位置。</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                <br />
              </p>
            </span>
          ),
        },
      },
    ],
  },
};

export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/logo-smart-white.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [{ name: 'content0', children: 'Xsmart TECH CO.,LTD' }],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '产品中心' },
        childWrapper: {
          children: [
            { name: 'link1', href: '#characterRecognition', children: '文字识别' },
            { name: 'link2', href: '#targetDetection', children: '目标检测' },
            { name: 'link3', href: '#sizeMeasurement', children: '尺寸测量' },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于' },
        childWrapper: {
          children: [
            { href: '#coreTechnology', name: 'link0', children: '核心技术' },
            { href: '#customerCase', name: 'link1', children: '客户案例' },
            { href: '#aboutUs', name: 'link1', children: '关于我们' },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '联系我们' },
        childWrapper: { children: [
            { href: '#contactUs', name: 'link1', children: 'AFX' },
          ] },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2020 by <a href="http://xmart.mygasmart.com">Xsmart</a> All Rights
        Reserved
      </span>
    ),
  },
};
