//导航栏
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/logo-xsmart.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'home',
        className: 'header3-item',
        children: {
          href: '/',
          children: [{ children: '首页', name: 'home' }],
        },
      },
      {
        name: 'productCenter',
        className: 'header3-item',
        children: {
          children: [{ children: '产品中心', name: 'text' }],
        },
        subItem: [
          {
            name: 'characterRecognition',
            className: 'item-sub',
            children: {
              href: '#characterRecognition',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '文字识别',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
          {
            name: 'targetDetection',
            className: 'item-sub',
            children: {
              href: '#targetDetection',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '目标检测',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
          {
            name: 'sizeMeasurement',
            className: 'item-sub',
            children: {
              href: '#sizeMeasurement',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '尺寸测量',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'coreTechnology',
        className: 'header3-item',
        children: {
          href: '#coreTechnology',
          children: [{ children: '核心技术', name: 'text' }],
        },
      },
      {
        name: 'customerCase',
        className: 'header3-item',
        children: {
          href: '#customerCase',
          children: [{ children: '客户案例', name: 'text' }],
        },
      },
      {
        name: 'aboutUs',
        className: 'header3-item',
        children: {
          href: '#aboutUs',
          children: [{ children: '关于我们', name: 'text' }],
        },
      },
      {
        name: 'contactUs',
        className: 'header3-item',
        children: {
          href: '#contactUs',
          children: [{ children: '联系我们', name: 'text' }],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};

export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>军 工 行 业 视 觉 检 测</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>单图查看，二次审核，精确分析</p>
          </span>
        ),
      },
    ],
  },
  img: {
    children:
      'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/jungong.jpg',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          className: 'content6-icon',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <p>追 溯 批 次</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <p>追溯产品</p>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>
                              <br />
                            </p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <p>自动探测</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block~kh4gf6h6bc',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>疵 点 定 位</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block~kh4gf9fqrxj',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <p>缺陷分类</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block~kh4gf9y9y5k',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <p>布边打标</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              </span>
            </span>
          ),
        },
      },
    ],
  },
};
export const Feature53DataSource = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '食 品 行 业 视 觉 检 测',
        className: 'title-h1',
      },
      { name: 'content', children: '多图查看，高效分析检测' },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: { children: '零食坚果', className: 'content7-tag-name' },
          icon: { children: 'codepen' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h2>
                  ◆ 机器视觉检测结果进行实时跟踪和记录，节省时间和额外的生产成本。
                </h2>
                <br />
                <h2>◆ 降低劳动力成本，提高行业竞争力。</h2>
                <br />
                <h2>◆ 提供产线软硬件及算法集成，整体解决方案。</h2>
                <br />
                <h2 style={{color: 'red'}}>◆ 合格率96%以上</h2>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/shipin1.png',
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'block1',
        tag: {
          className: 'content7-tag',
          icon: { children: 'codepen' },
          text: { className: 'content7-tag-name', children: '零食包装' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 7,
            xs: 24,
            children: (
              <span>
                <h2>● 追溯批次</h2>
                <br />
                <h2>● 追溯产品</h2>
                <br />
                <h2>● 追溯检验流程</h2>
                <br />
                <h2>● 自动计算批次缺陷率</h2>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 17,
            xs: 24,
            children:
              'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/shipin2.png',
          },
        },
      },
    ],
  },
};
export const Feature54DataSource = {
  wrapper: {className: 'home-page-wrapper content7-wrapper'},
  page: {className: 'home-page content7'},
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '生 物 医 药 行 业 视 觉 检 测',
        className: 'title-h1',
      },
      {name: 'content', children: '快速部署，节约成本，快速高效'},
    ],
  },
  tabsWrapper: {className: 'content7-tabs-wrapper'},
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: {children: '基因芯片', className: 'content7-tag-name'},
          icon: {children: 'CodeSandbox'},
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h2>需求目标:</h2>
                <br/>
                <h4>解决软件现有判读问题：信号弱、不规则、重叠等异常；人工复核率较高；准确度不够高；</h4>
                <br/>
                <h2>
                   POC 验 证 结 果
                </h2>
                <br/>
                <h3>① 综合正确率：<span style={{color: 'red'}}>提升至99.9%以上</span></h3>
                <br/>
                <h3>② 人工复核提示率：<span style={{color: 'red'}}>降至3%以下</span></h3>
                <br/>
                <h3>③ 在现有软件的基础上，对识别模块进行优化或重新实现，模块提供接口，供现有软件调用。</h3>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/other1.png',
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'block1',
        tag: {
          className: 'content7-tag',
          icon: {children: 'CodeSandbox'},
          text: {className: 'content7-tag-name', children: '蛋白质芯片'},
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h2>需求目标:</h2>
                <br/>
                <h4>解决软件现有异常影响判读的问题：</h4>
                <h3>①　对各类异常进行判断提示，异常包括：明暗不均、拖尾溢出、异常亮点、不规整等.</h3>
                <h3>②　对典型的异常进行灰度值恢复或者优化计算.</h3>
                <br/>
                <h2>
                   POC 验 证 结 果
                </h2>
                <br/>
                <h3>①　异常判读综合正确率：提升至<span style={{color: 'red'}}>99.9%以上</span></h3>
                <br/>
                <h3>② 人工复核提示率：<span style={{color: 'red'}}>降至3%以下</span></h3>
                <br/>
                <h3>无需mark列标记，可针对明暗不均、异常亮点等可以进行灰度恢复.</h3>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/other2.jpg'
          },
        },
      },
    ],
  },
};
export const Feature55DataSource = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '3C 行 业 视 觉 检 测',
        className: 'title-h1',
      },
      { name: 'content', children: '高速智能，精密识别' },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: { className: 'content7-tag-name',children: '玻璃盖板'},
          icon: { children: 'codepen' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h2>需求目标:</h2>
                <br/>
                <h4>针对目前人工漏判率高，准确度低，检测效率较低下的问题，现要实现对玻璃盖板的缺陷检测.</h4>
                <h2>
                   POC 验 证 结 果
                </h2>
                <br />
                {/*<img src="" style={{width:'100px',height:'100px',float:'left',alt:'img'}}/>*/}
                {/*<img src="" style={{width:'100px',height:'100px',float:'left',alt:'img'}}/>*/}
                {/*<img src="" style={{width:'200px',height:'200px',alt:'img'}}/>*/}
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/over.png',
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'block1',
        tag: {
          className: 'content7-tag',
          icon: { children: 'codepen' },
          text: { className: 'content7-tag-name', children: '冰箱' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h2>➤OCR识别</h2>
                <br/>
                <h4>
                  实现冰箱能耗贴、商标、前后一维码的识别检测，保持标识完整性和贴覆的准确性；
                  100%准确率
                </h4>
                <br/>
                <h2>
                  缺陷检测：
                </h2>
                <br />
                <h3>①　实现冰箱两门、四门各门体前后不平、高低不齐以及门与门之间平行度检测；</h3>
                <br />
                <h3>② 门表面划痕、凹凸坑、划伤、掉漆、色差等缺陷检测。</h3>

              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/refrigerator1.png',
          },
        },
      },
      {
        name: 'block2',
        tag: {
          className: 'content7-tag',
          icon: { children: 'codepen' },
          text: { className: 'content7-tag-name', children: '手机屏幕' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h2>◑ 手 机 屏 幕:</h2>
                <br/>
                <h3>白点、白团、黑点、黑团、彩色团、亮点、条纹、划痕等缺陷检测</h3>
                <br/>
                <h4>◈ 检测出有缺陷的手机编号会高亮为红色</h4>
                <h4>◈ 算法不合格提交：点击提交按钮，会传输至AI中台算法复判数据集中，用于算法自学与算法优化</h4>
                <h4>◈ 检测多种产品规格</h4>
                <h4>◈ 展示结果并生成统计报表，NG手机产线实时显示</h4>
                <h4>◈ 检测精度：<span style={{color:'red'}}>99.5%以上</span></h4>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/mubiao4.png',
          },
        },
      },
      {
        name: 'block3',
        tag: {
          className: 'content7-tag',
          icon: { children: 'codepen' },
          text: { className: 'content7-tag-name', children: '触控版尺寸测量' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h2>需求目标:</h2>
                <br/>
                <h4>解决软件现有异常影响判读的问题：</h4>
                <h3>①　对各类异常进行判断提示，异常包括：明暗不均、拖尾溢出、异常亮点、不规整等.</h3>
                <h3>②　对典型的异常进行灰度值恢复或者优化计算.</h3>
                <br/>
                <h2>
                   POC 验 证 结 果
                </h2>
                <br />
                <h3>①　异常判读综合正确率：提升至<span style= {{color: 'red'}}>99.9%以上</span></h3>
                <br />
                <h3>② 人工复核提示率：<span style= {{color: 'red'}}>降至3%以下</span></h3>
                <br />
                <h3>无需mark列标记，可针对明暗不均、异常亮点等可以进行灰度恢复.</h3>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/chicunceliang.png',
          },
        },
      },
    ],
  },
};

//页尾
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/logo-smart-white.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [{ name: 'content0', children: 'Xsmart TECH CO.,LTD' }],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '产品中心' },
        childWrapper: {
          children: [
            { name: 'link1', href: '#characterRecognition', children: '文字识别' },
            { name: 'link2', href: '#targetDetection', children: '目标检测' },
            { name: 'link3', href: '#sizeMeasurement', children: '尺寸测量' },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于' },
        childWrapper: {
          children: [
            { href: '#coreTechnology', name: 'link0', children: '核心技术' },
            { href: '#customerCase', name: 'link1', children: '客户案例' },
            { href: '#aboutUs', name: 'link1', children: '关于我们' },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '联系我们' },
        childWrapper: { children: [
            { href: '#contactUs', name: 'link1', children: 'AFX' },
          ] },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2020 by <a href="http://xmart.mygasmart.com">Xsmart</a> All Rights
        Reserved
      </span>
    ),
  },
};
