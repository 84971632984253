import {HashRouter, Route, Switch} from "react-router-dom";
import CharacterRecognition from "../view/productCenter/characterRecognition";
import TargetDetection from "../view/productCenter/targetDetection";
import SizeMeasurement from "../view/productCenter/sizeMeasurement";
import CoreTechnology from "../view/coreTechnology";
import CustomerCase from "../view/customerCase";
import AboutUs from "../view/aboutUs";
import ContactUs from "../view/contactUs";
import Home from "../view/home";
import React from "react";

const BasicRoute = () => (
  <HashRouter>
    <Switch>
      <Route path="/characterRecognition">
        <CharacterRecognition/>
      </Route>
      <Route path="/targetDetection">
        <TargetDetection/>
      </Route>
      <Route path="/sizeMeasurement">
        <SizeMeasurement/>
      </Route>
      <Route path="/coreTechnology">
        <CoreTechnology/>
      </Route>
      <Route path="/customerCase">
        <CustomerCase/>
      </Route>
      <Route path="/aboutUs">
        <AboutUs/>
      </Route>
      <Route path="/contactUs">
        <ContactUs/>
      </Route>
      <Route path="/">
        <Home/>
      </Route>
    </Switch>
  </HashRouter>
);

export default BasicRoute;
