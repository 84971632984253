import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Form } from 'antd';

const formLayout = {
  labelCol: { span: 11 },
  wrapperCol: { span: 6 },
};

class Content11 extends React.PureComponent {
  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    return (
      <OverPack {...props} {...dataSource.OverPack}>
          <Form {...formLayout}>
            <QueueAnim
              type="bottom"
              leaveReverse
              key="page"
              delay={[0, 100]}
              {...dataSource.titleWrapper}
            >
            <div key="1" className="title-content">
              <Form.Item label="联系人">
                <div style={{float: 'left'}}>陈凯  15151805610</div>
              </Form.Item>
            </div>
            <div key="2" className="title-content">
              <Form.Item label="邮箱">
                <div style={{float: 'left'}}>chenkai@xsmart2019.onaliyun.com</div>
              </Form.Item>
            </div>
            <div key="3" className="title-content">
              <Form.Item label="微信二维码">
                <img src="https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/ChenKaiQRcode.png" style={{width: '120px', height: '120px', float: 'left'}}/>
              </Form.Item>
            </div>
            <div key="4" className="title-content">
              <Form.Item label="地址">
                <div style={{float: 'left'}}>南京市江宁区未来网络小镇9号楼1706</div>
              </Form.Item>
            </div>
            </QueueAnim>
          </Form>
      </OverPack>
    );
  }
}

export default Content11;
