import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from './utils';

class Content13 extends React.PureComponent {
  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    return (
      <OverPack {...props} {...dataSource.OverPack}>
        <QueueAnim
          type="bottom"
          leaveReverse
          key="page"
          delay={[0, 100]}
          {...dataSource.titleWrapper}
        >
          <div >
            <img src={'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/homePicture1.jpg'}
                 width={'400px'}height={'300px'} />
            <img src={'https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/homePicture2.jpg'}
                 width={'400px'}height={'300px'}/>
          </div>
          {dataSource.titleWrapper.children.map(getChildrenToRender)}
        </QueueAnim>
      </OverPack>
    );
  }
}

export default Content13;
